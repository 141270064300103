html,
body,
#root,
.App {
  height: 100%;
}

.App {
  padding: 20px;
}

.logo {
  height: 40px;
  margin: 20px;
}

.box-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100% - 37px);
}

.box-version {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}