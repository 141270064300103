.icon-button {
  height: 56px;
  width: 56px;
  background-color: black !important;
}

.icon-button:hover {
  background-color: #555555 !important;
}

.icon-button:disabled {
  background-color: lightgray !important;
}

.icon {
  color: white;
  border-radius: 50%;
  font-size: 34px !important;
}

.address-input {
  width: 660px;
  max-width: 660px;
}
